import '@/styles/globals.css'
import 'tailwindcss/tailwind.css'
import { useEffect } from 'react'

import type { AppProps } from 'next/app'
import Script from 'next/script'
import { useRouter } from 'next/router'
import { datadogRum } from '@datadog/browser-rum'
import * as fbq from '../lib/fbpixel'
import {
  HISTORY_LENGTH,
  INITIAL_HISTORY,
  initPageViewHistory,
} from '../lib/initPageViewHistory'
function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  useEffect(() => {
    initPageViewHistory()

    const handleRouteChange = (url) => {
      fbq.pageview()

      // track the three previous pages navigated to for referral purposes
      const pageHistory = JSON.parse(
        localStorage.getItem('page-referrals') ||
          JSON.stringify(INITIAL_HISTORY)
      )

      // if the most recent entry in the cache is the same url, don't add it again
      if (pageHistory[pageHistory.length - 1] === url) {
        return
      }
      // add the current page to the history
      pageHistory.push(url)

      // We only need to keep HISTORY_LENGTH entries, so trim off the oldest route
      if (pageHistory.length > HISTORY_LENGTH) {
        pageHistory.shift()
      }

      localStorage.setItem('page-referrals', JSON.stringify(pageHistory))
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID || '',
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || '',
      site: 'datadoghq.com',
      service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
      env: process.env.NEXT_PUBLIC_DATADOG_ENVIRONMENT,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sampleRate: 100,
      sessionReplaySampleRate: 100,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    })
    datadogRum.startSessionReplayRecording()

    return () => {
      datadogRum.stopSessionReplayRecording()
    }
  }, [])

  const TikTokPixelScript = () => {
    if (process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID === undefined) return <></>
    return (
      <Script id="ttp" strategy="afterInteractive">
        {`
      !function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
      
        ttq.load('${process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID}');
        ttq.page();
      }(window, document, 'ttq');
      `}
      </Script>
    )
  }

  return (
    <>
      <TikTokPixelScript />
      <Component {...pageProps} />
    </>
  )
}
export default MyApp
